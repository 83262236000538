import * as i0 from '@angular/core';
import { inject, Injectable, NgModule } from '@angular/core';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { getYear, getMonth, getDate, getDay, getDaysInMonth, parseISO, parse, format, addYears, addMonths, addDays, formatISO, isDate, isValid, set, getHours, getMinutes, getSeconds, addSeconds } from 'date-fns';

/** Creates an array and fills it with values. */
function range(length, valueFunction) {
  const valuesArray = Array(length);
  for (let i = 0; i < length; i++) {
    valuesArray[i] = valueFunction(i);
  }
  return valuesArray;
}
// date-fns doesn't have a way to read/print month names or days of the week directly,
// so we get them by formatting a date with a format that produces the desired month/day.
const MONTH_FORMATS = {
  long: 'LLLL',
  short: 'LLL',
  narrow: 'LLLLL'
};
const DAY_OF_WEEK_FORMATS = {
  long: 'EEEE',
  short: 'EEE',
  narrow: 'EEEEE'
};
/** Adds date-fns support to Angular Material. */
class DateFnsAdapter extends DateAdapter {
  constructor() {
    super();
    const matDateLocale = inject(MAT_DATE_LOCALE, {
      optional: true
    });
    this.setLocale(matDateLocale);
  }
  getYear(date) {
    return getYear(date);
  }
  getMonth(date) {
    return getMonth(date);
  }
  getDate(date) {
    return getDate(date);
  }
  getDayOfWeek(date) {
    return getDay(date);
  }
  getMonthNames(style) {
    const pattern = MONTH_FORMATS[style];
    return range(12, i => this.format(new Date(2017, i, 1), pattern));
  }
  getDateNames() {
    const dtf = typeof Intl !== 'undefined' ? new Intl.DateTimeFormat(this.locale.code, {
      day: 'numeric',
      timeZone: 'utc'
    }) : null;
    return range(31, i => {
      if (dtf) {
        // date-fns doesn't appear to support this functionality.
        // Fall back to `Intl` on supported browsers.
        const date = new Date();
        date.setUTCFullYear(2017, 0, i + 1);
        date.setUTCHours(0, 0, 0, 0);
        return dtf.format(date).replace(/[\u200e\u200f]/g, '');
      }
      return i + '';
    });
  }
  getDayOfWeekNames(style) {
    const pattern = DAY_OF_WEEK_FORMATS[style];
    return range(7, i => this.format(new Date(2017, 0, i + 1), pattern));
  }
  getYearName(date) {
    return this.format(date, 'y');
  }
  getFirstDayOfWeek() {
    return this.locale.options?.weekStartsOn ?? 0;
  }
  getNumDaysInMonth(date) {
    return getDaysInMonth(date);
  }
  clone(date) {
    return new Date(date.getTime());
  }
  createDate(year, month, date) {
    if (typeof ngDevMode === 'undefined' || ngDevMode) {
      // Check for invalid month and date (except upper bound on date which we have to check after
      // creating the Date).
      if (month < 0 || month > 11) {
        throw Error(`Invalid month index "${month}". Month index has to be between 0 and 11.`);
      }
      if (date < 1) {
        throw Error(`Invalid date "${date}". Date has to be greater than 0.`);
      }
    }
    // Passing the year to the constructor causes year numbers <100 to be converted to 19xx.
    // To work around this we use `setFullYear` and `setHours` instead.
    const result = new Date();
    result.setFullYear(year, month, date);
    result.setHours(0, 0, 0, 0);
    // Check that the date wasn't above the upper bound for the month, causing the month to overflow
    if (result.getMonth() != month && (typeof ngDevMode === 'undefined' || ngDevMode)) {
      throw Error(`Invalid date "${date}" for month with index "${month}".`);
    }
    return result;
  }
  today() {
    return new Date();
  }
  parse(value, parseFormat) {
    if (typeof value == 'string' && value.length > 0) {
      const iso8601Date = parseISO(value);
      if (this.isValid(iso8601Date)) {
        return iso8601Date;
      }
      const formats = Array.isArray(parseFormat) ? parseFormat : [parseFormat];
      if (!parseFormat.length) {
        throw Error('Formats array must not be empty.');
      }
      for (const currentFormat of formats) {
        const fromFormat = parse(value, currentFormat, new Date(), {
          locale: this.locale
        });
        if (this.isValid(fromFormat)) {
          return fromFormat;
        }
      }
      return this.invalid();
    } else if (typeof value === 'number') {
      return new Date(value);
    } else if (value instanceof Date) {
      return this.clone(value);
    }
    return null;
  }
  format(date, displayFormat) {
    if (!this.isValid(date)) {
      throw Error('DateFnsAdapter: Cannot format invalid date.');
    }
    return format(date, displayFormat, {
      locale: this.locale
    });
  }
  addCalendarYears(date, years) {
    return addYears(date, years);
  }
  addCalendarMonths(date, months) {
    return addMonths(date, months);
  }
  addCalendarDays(date, days) {
    return addDays(date, days);
  }
  toIso8601(date) {
    return formatISO(date, {
      representation: 'date'
    });
  }
  /**
   * Returns the given value if given a valid Date or null. Deserializes valid ISO 8601 strings
   * (https://www.ietf.org/rfc/rfc3339.txt) into valid Dates and empty string into null. Returns an
   * invalid date for all other values.
   */
  deserialize(value) {
    if (typeof value === 'string') {
      if (!value) {
        return null;
      }
      const date = parseISO(value);
      if (this.isValid(date)) {
        return date;
      }
    }
    return super.deserialize(value);
  }
  isDateInstance(obj) {
    return isDate(obj);
  }
  isValid(date) {
    return isValid(date);
  }
  invalid() {
    return new Date(NaN);
  }
  setTime(target, hours, minutes, seconds) {
    if (typeof ngDevMode === 'undefined' || ngDevMode) {
      if (hours < 0 || hours > 23) {
        throw Error(`Invalid hours "${hours}". Hours value must be between 0 and 23.`);
      }
      if (minutes < 0 || minutes > 59) {
        throw Error(`Invalid minutes "${minutes}". Minutes value must be between 0 and 59.`);
      }
      if (seconds < 0 || seconds > 59) {
        throw Error(`Invalid seconds "${seconds}". Seconds value must be between 0 and 59.`);
      }
    }
    return set(this.clone(target), {
      hours,
      minutes,
      seconds,
      milliseconds: 0
    });
  }
  getHours(date) {
    return getHours(date);
  }
  getMinutes(date) {
    return getMinutes(date);
  }
  getSeconds(date) {
    return getSeconds(date);
  }
  parseTime(value, parseFormat) {
    return this.parse(value, parseFormat);
  }
  addSeconds(date, amount) {
    return addSeconds(date, amount);
  }
  static ɵfac = function DateFnsAdapter_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || DateFnsAdapter)();
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: DateFnsAdapter,
    factory: DateFnsAdapter.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DateFnsAdapter, [{
    type: Injectable
  }], () => [], null);
})();
const MAT_DATE_FNS_FORMATS = {
  parse: {
    dateInput: 'P',
    timeInput: 'p'
  },
  display: {
    dateInput: 'P',
    timeInput: 'p',
    monthYearLabel: 'LLL uuuu',
    dateA11yLabel: 'PP',
    monthYearA11yLabel: 'LLLL uuuu',
    timeOptionLabel: 'p'
  }
};
class DateFnsModule {
  static ɵfac = function DateFnsModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || DateFnsModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: DateFnsModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    providers: [{
      provide: DateAdapter,
      useClass: DateFnsAdapter,
      deps: [MAT_DATE_LOCALE]
    }]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DateFnsModule, [{
    type: NgModule,
    args: [{
      providers: [{
        provide: DateAdapter,
        useClass: DateFnsAdapter,
        deps: [MAT_DATE_LOCALE]
      }]
    }]
  }], null, null);
})();
class MatDateFnsModule {
  static ɵfac = function MatDateFnsModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || MatDateFnsModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: MatDateFnsModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    providers: [provideDateFnsAdapter()]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatDateFnsModule, [{
    type: NgModule,
    args: [{
      providers: [provideDateFnsAdapter()]
    }]
  }], null, null);
})();
function provideDateFnsAdapter(formats = MAT_DATE_FNS_FORMATS) {
  return [{
    provide: DateAdapter,
    useClass: DateFnsAdapter,
    deps: [MAT_DATE_LOCALE]
  }, {
    provide: MAT_DATE_FORMATS,
    useValue: formats
  }];
}
export { DateFnsAdapter, DateFnsModule, MAT_DATE_FNS_FORMATS, MatDateFnsModule, provideDateFnsAdapter };
